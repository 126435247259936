import React from "react";

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
AOS.init();

const cards = [
  {
    number: "25+",
    tag: "Delivered products",
    src: "/assets/product.svg",
  },
  {
    number: "6+",
    tag: "Operating teams",
    src: "/assets/teams.svg",
  },
  {
    number: "20",
    tag: "Developers",
    src: "/assets/developers.svg",
  },
  {
    number: "20+",
    tag: "Trusted clients",
    src: "/assets/clients.svg",
  },
];

const Numbers = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center md:h-[70vh] lg:h-screen relative">
      <h1 className="text-center font-medium leading-tight mb-5 xl:text-6xl md:text-5xl text-3xl">
        Let the numbers <br />
        speak for ourselves
      </h1>
      <p className="text-gray-400 md:text-2xl font-light text-xl">
        Our success through numbers
      </p>
      <span className="absolute z-30 xl:right-20 right-0 lg:text-lg text-base rotate-[30deg] bg-card-gradient py-2 px-6 rounded-3xl hidden md:block">
        #globalNetworks
      </span>
      <div className="flex md:flex-row flex-col md:justify-around justify-center items-center xl:px-24 px-12 pt-14 pb-12 md:pb-0 w-full xl:gap-10 lg:gap-0 gap-6">
        {cards.map((card) => {
          return (
            <>
              <div
                data-aos="zoom-in-up"
                className="flex flex-col items-center md:w-1/5 w-4/5"
              >
                <div className="xl:w-[35vh] xl:h-[35vh] lg:w-[30vh] lg:h-[30vh] md:h-[25vh] md:w-[25vh] h-[32vh] w-[32vh] bg-card-bg-1 rounded-3xl flex justify-center flex-col items-center xl:text-xl lg:text-lg md:text-sm text-xl">
                  <h1 className="xl:text-8xl lg:text-7xl md:text-6xl text-7xl mb-2">
                    {card.number}
                  </h1>
                  <p>{card.tag}</p>
                </div>
                <div className="bg-card-bg-3 bg-opacity-25 rounded-full lg:-mt-12 md:-mt-8 -mt-10">
                  <img
                    src={card.src}
                    alt=""
                    className="bg-card-gradient p-2 m-2 lg:p-3 lg:m-3 rounded-full"
                  />
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default Numbers;
