import Marquee from "react-fast-marquee";
import { gallery } from "../../../constants/gallery";
export default function Gallery() {
  return (
    <div
      id="gallery"
      className=" lg:h-screen h-fit w-full lg:pb-[1.5rem] lg:pt-[1rem] sm:pb-[8rem] pb-[3rem] flex flex-col items-center lg:justify-between justify-center bg-primary-bg"
    >
      <div className="bg-primary-bg rounded-3xl py-[1.5rem] lg:h-[90%] lg:max-h-[600px] w-full flex flex-col items-center justify-start md:space-y-[4rem] space-y-[2rem]">
        <h1 className="skRegular sm:text-[2.25rem] text-lg sm:leading-[1] leading-3 flex-col text-left flex w-full lg:px-[2.5rem] px-[0.75rem] ">
          <span className="text-white/65">Our gallery of </span> <br />
          <span className="skBold lg:text-[4.25rem] sm:text-[3rem] text-3xl">
            Business engagements
          </span>
        </h1>
        <div className="w-full flex items-center justify-center">
          <Marquee autoFill direction="right" pauseOnHover>
            {gallery.map((el) => (
              <GalleryCard el={el} />
            ))}
          </Marquee>
        </div>
      </div>
    </div>
  );
}

function GalleryCard({ el }) {
  return (
    <div className="relative w-fit flex flex-col items-center justify-center space-y-3 mr-[2rem]">
      <div className="w-[21.25rem] h-[16rem] rounded-xl overflow-hidden">
        <img src={el.source} className="object-cover w-full h-full "></img>
      </div>
      <div className="relative">
        <img src="./v2/galleryCard.svg"></img>
        <h1 className="absolute top-[4%] neueRegular text-xl left-[0.75rem] ">
          {el.name}
        </h1>
        <div className="flex items-center justify-center space-x-3 absolute bottom-[0.75rem] left-[0.75rem]">
          <img src="./v2/symbols/calendar_gallery.svg"></img>
          <h3 className="text-white">{el.date}</h3>
        </div>
        <div className=" absolute bottom-[0] right-[0] w-[48%] rounded-xl bg-white/20 p-2 ">
          <h3 className="text-white text-center ">{el.loc}</h3>
        </div>
      </div>
    </div>
  );
}
