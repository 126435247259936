import { useState } from "react";
import { useNavigate } from "react-router-dom";
export default function Navbar() {
  const navigate = useNavigate();
  const nav = [
    { name: "About", link: "/#about" },
    { name: "Services", link: "/#services" },
    { name: "Clients", link: "/#clients" },
    { name: "Works", link: "/#works" },
    { name: "Gallery", link: "/#gallery" },
    { name: "Career", link: "/career" },
  ];
  const [current, setCurrent] = useState(nav[0].name);
  const [open, setOpen] = useState(false);

  const close = (el) => {
    setCurrent(el);
    setOpen(false);
  };

  return (
    <div
      className={`z-10 min-w-screen max-w-[1440px] w-full ${
        open ? "h-fit" : "h-[10%]"
      } flex items-center justify-center gap-4 fixed bottom-[0.5rem] lg:px-[2.5rem] px-[.75rem] `}
    >
      <div className="md:flex hidden items-center justify-between w-fit lg:space-x-1 space-x-[2px]">
        <a
          target="_blank"
          href="https://www.linkedin.com/company/metaborong-technologies/"
        >
          <div className="lg:p-[1.2rem] p-[0.5rem] lg:w-[4rem] w-[2.25rem] rounded-full bg-white/25 backdrop-blur-sm">
            <img src="./v2/symbols/linkedin.svg"></img>
          </div>
        </a>
        <a target="_blank" href="https://x.com/Metaborong">
          <div className="lg:p-[1.2rem] p-[0.5rem] lg:w-[4rem] w-[2.25rem] flex items-center rounded-full bg-white/25 backdrop-blur-sm">
            <img src="./v2/symbols/twitter.svg"></img>
          </div>
        </a>
        <a target="_blank" href="https://dribbble.com/Metaborong">
          <div className="lg:p-[1.2rem] p-[0.5rem] lg:w-[4rem] w-[2.25rem] flex items-center rounded-full bg-white/25 backdrop-blur-sm">
            <img src="./v2/symbols/dribble.svg"></img>
          </div>
        </a>
      </div>
      <div
        className={`flex flex-col md:w-fit w-full bg-white text-black md:rounded-full rounded-3xl sm:h-fit  ${
          open ? "" : "h-[6vh] items-center justify-center"
        } `}
      >
        {open && (
          <div className="flex flex-col md:hidden px-4 py-8">
            {nav.map((el) => (
              <a
                onClick={() => close(el.name)}
                className="mb-2 text-2xl font-medium border-b-2 pb-2 flex justify-between"
                href={el.link}
              >
                {el.name}{" "}
                <img
                  src="/assets/clients/arrow-up-right.svg"
                  className="w-8 h-8 invert"
                  alt=""
                />
              </a>
            ))}
            <div className="flex items-center justify-center w-full pt-2 space-x-2">
              <a
                target="_blank"
                href="https://www.linkedin.com/company/metaborong-technologies/"
              >
                <div className="lg:p-[1.2rem] p-[0.5rem] lg:w-[4rem] w-[2.25rem] rounded-full bg-black backdrop-blur-sm">
                  <img src="./v2/symbols/linkedin.svg"></img>
                </div>
              </a>
              <a target="_blank" href="https://x.com/Metaborong">
                <div className="lg:p-[1.2rem] p-[0.5rem] lg:w-[4rem] w-[2.25rem] flex items-center rounded-full bg-black backdrop-blur-sm">
                  <img src="./v2/symbols/twitter.svg"></img>
                </div>
              </a>
              <a target="_blank" href="https://dribbble.com/Metaborong">
                <div className="lg:p-[1.2rem] p-[0.5rem] lg:w-[4rem] w-[2.25rem] flex items-center rounded-full bg-black backdrop-blur-sm">
                  <img src="./v2/symbols/dribble.svg"></img>
                </div>
              </a>
            </div>
          </div>
        )}
        <div className="rounded-full lg:space-x-[2rem] space-x-3 flex items-center justify-between lg:pr-[1.5rem] pr-1 md:w-fit w-full">
          <img
            src="/assets/hero-meta-logo.svg"
            className="m-1 lg:w-[4rem] sm:w-[2.25rem] w-[2.5rem] rounded-full"
          ></img>
          <div className="md:flex hidden gap-6 items-center justify-around h-full lg:text-lg md:text-sm text-xs skRegular">
            <a href="/#about">About</a>
            <a href="/#services">Services</a>
            <a href="/#clients">Clients</a>
            <a href="/#works">Works</a>
            <a href="/#gallery">Gallery</a>
            {/* <a>Learn</a> */}
            <a href="/career">Career</a>
          </div>
          <div
            onClick={() => setOpen(!open)}
            className="flex justify-center md:hidden bg-slate-200 rounded-full font-medium w-1/2 sm:py-1 py-2 my-3 text-center"
          >
            <div className="flex items-center gap-2">
              <p className="text-center flex w-full mx-auto">{current}</p>
              <img
                src="./v2/symbols/circle-up-arrow.svg"
                alt=""
                className={` w-5 h-5 ${open ? "rotate-180" : ""}`}
              />
            </div>
          </div>
          <img src="/assets/nav-vector.svg" className="lg:m-1"></img>
        </div>
      </div>
      {/* <button className="rounded-full lg:py-[1.25rem] py-[0.5rem] lg:px-[2rem] px-[1rem] bg-white/25 backdrop-blur-sm flex items-center justify-center space-x-2">
        <img src="./v2/symbols/calendar.svg"></img>
        <p className="skBold">Schedule a call</p>
      </button> */}
    </div>
  );
}
