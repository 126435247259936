import React from 'react'
import { useState } from 'react'
import { useQuoteState } from '../context/Quote';

const GetQuote = () => {
    const {quote, setQuote} = useQuoteState()
    const [status, setStatus] = useState({ideation: false, inProgress: false, toLaunch: false})

    const [thanks, setThanks] = useState(false)

    const thankYou = () =>{
        setThanks(true)
        setTimeout(() => {
            setThanks(false)
            setQuote(false)
        }, 3000);
    }

    if(thanks) {
       return <PopUp/>
    } else{
    return (
        <div className="bg-primary-bg text-offwhite overflow-x-hidden scroll-smooth py-14 px-8 h-screen w-screen fixed z-[100] top-0 left-0">
            <a className="absolute cursor-pointer top-6 right-6" onClick={() => setQuote(false)}><img src="/assets/close.svg" /></a>
            <div className="md:w-4/5 lg:w-3/5 w-full relative">
                <h1 className="w-full font-semibold xl:text-5xl lg:text-4xl md:text-3xl text-3xl leading-[1.10]">Get A Quote For Your Project</h1>
                <p className="opacity-60 xl:text-[22px] lg:text-base md:text-sm lg:mt-3 md:mt-1 mt-2" >Request A Customised Price Quote Tailored To Your Needs</p>
            </div>
            <div className="flex flex-col gap-6 md:gap-0 md:flex-row justify-between xl:mt-16 lg:mt-12 mt-10">
                <div className="md:w-5/12">
                    <p className="xl:text-xl lg:text-lg">What's The Current Status Of Your Project</p>
                    <div className="xl:mt-10 mt-6 flex flex-col gap-5">
                        <div onClick={() => setStatus({ideation: true, inProgress: false, toLaunch: false})} className={`flex items-center gap-4 xl:py-4 py-2 md:px-4 px-2 rounded-lg lg:text-base text-sm xl:text-xl ${status.ideation ? "bg-card-gradient border" : "bg-card-bg-1"}`}>
                            <img className="w-fit md:w-1/6 lg:w-fit" src="/assets/bulb.svg" alt="" />
                            <p>We Are In Ideation Phase</p>
                        </div>
                        <div onClick={() => setStatus({ideation: false, inProgress: true, toLaunch: false})} className={`flex items-center gap-4 xl:py-4 py-2 md:px-4 px-2 rounded-lg lg:text-base text-sm xl:text-xl ${status.inProgress ? "bg-card-gradient border" : "bg-card-bg-1"}`}>
                            <img className="w-fit md:w-1/6 lg:w-fit" src="/assets/sdk.svg" alt="" />
                            <p>We Have A Product In Progress</p>
                        </div>
                        <div onClick={() => setStatus({ideation: false, inProgress: false, toLaunch: true})} className={`flex items-center gap-4 xl:py-4 py-2 md:px-4 px-2 rounded-lg lg:text-base text-sm xl:text-xl ${status.toLaunch ? "bg-card-gradient border" : "bg-card-bg-1"}`}>
                            <img className="w-fit md:w-1/6 lg:w-fit" src="/assets/rocket_launch.svg" alt="" />
                            <p>We Are Just One Step Ahead To Launch</p>
                        </div>
                    </div>
                </div>
                <div className="md:w-7/12">
                    <form action="" className="flex flex-col xl:gap-12 md:gap-10 gap-6 md:px-16">
                        <div className="flex flex-col">
                            <label htmlFor="name" className="text-xl xl:text-[22px]">Name</label>
                            <input placeholder="Enter your name" className="bg-transparent border-b-2 border-white py-2 focus:outline-none lg:text-lg text-opacity-20" id="name" type="text" />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="summary" className="text-xl xl:text-[22px]">Summary</label>
                            <textarea placeholder="Tell us about your project in brief" id="summary" className="bg-transparent border-b-2 border-white py-2 h-[30vh] focus:outline-none lg:text-lg text-opacity-20" type="textarea" />
                        </div>
                        <button type="submit" onClick={() => thankYou()} className="bg-secondary-bg p-4 rounded-full duration-100 text-xl xl:text-xl md:text-xl font-semibold tracking-wide hover:bg-offwhite hover:text-black">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    )}
}

export default GetQuote

const PopUp = () => {
    return(
      <div className="fixed z-50 top-0 left-0 flex justify-center items-center w-full h-full">
          <div className="bg-secondary-bg md:w-1/3 m-4 h-1/2 flex justify-center items-center flex-col shadow-[0_20px_50px_rgba(8,_112,_184,_0.7)] rounded-xl">
            <img className="w-1/6" src="/assets/checkmark.png" alt="" />
            <h2 className="text-3xl my-3">Thank You!</h2>
          </div>
      </div>
    )
  }