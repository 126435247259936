export const services = [
  {
    name: "Blockchain",
    description:
      "We build secure, transparent, and efficient blockchain systems for transactions, smart contracts, and DApps.",
    img: "/assets/blockchain.svg",
  },
  {
    name: "IoT",
    description:
      "We provide custom IoT solutions to monitor, collect, and analyse data from connected devices in real time",
    img: "/assets/IoT.svg",
  },
  {
    name: "AI/ML",
    description:
      "We serve advanced AI and ML models tailored to business needs, enhancing decision-making capabilities.",
    img: "/assets/AI.svg",
  },
  {
    name: "Web solutions",
    description:
      "We craft best web experiences for your SaaS ideas and boost your productivity.",
    img: "/assets/web.svg",
  },
  {
    name: "App solutions",
    description:
      "We specialize in designing and developing innovative applications tailored to meet the unique needs of our clients.",
    img: "/assets/mobiledev.svg",
  },
];

export const servicesV2 = [
  {
    name: "WEB3",
  },
  {
    name: "WEB2",
  },
  {
    name: "IoT",
  },
  {
    name: "AI/ML",
  },
];

export const projects = [
  {
    title: "Web 3.0",
    description:
      "Elevate your digital presence with our expert Web3 development services. Be it NFT staking and minting, revolutionising finance with DeFi solutions, or tokenising real-world assets, we craft seamless, innovative solutions. Ready to build the future together?",
    about:
      "Elevate your digital presence with seamless Web3 solutions, from NFT staking to DeFi innovation",
    src: [
      "./v2/services/web3_1.svg",
      "./v2/services/web3_3.svg",
      "./v2/services/web3_2.svg",
    ],
    color: "#080f19",
    link: "https://www.ignant.com/2023/03/25/ad2186-matthias-leidingers-photographic-exploration-of-awe-and-wonder/",
  },
  {
    title: "Web 2.0",
    description:
      "Explore our expert Web2 development services. We build everything from SAAS platforms to dynamic web applications, e-commerce solutions, delivering user-friendly interfaces, optimised performance, and scalable solutions. Let's bring your vision to life.",
    about:
      "Elevate your online ventures with robust Web2 solutions, from SAAS platforms to e-commerce innovation.",
    src: [
      "./v2/services/typescript.svg",
      "./v2/services/web2_3.svg",
      "./v2/services/nginix.svg",
    ],
    color: "#191e19",
    link: "https://www.ignant.com/2023/03/25/ad2186-matthias-leidingers-photographic-exploration-of-awe-and-wonder/",
  },
  {
    title: "AI/ML",
    description:
      "Transform your Operations with Intelligent AI/ML Solutions! Unlock the full potential of predictive analytics, data-driven insights, language processing, automation, computer vision, and beyond. Stay ahead of the curve with tools that are customised to your needs.",
    about:
      "Elevate your operations with custom AI/ML solutions, unlocking advanced analytics, automation, and computer vision.",
    src: [
      "./v2/services/ai_2.svg",
      "./v2/services/ai_1.svg",
      "./v2/services/ai_3.svg",
    ],
    color: "#333AFE",
    link: "https://www.ignant.com/2023/03/25/ad2186-matthias-leidingers-photographic-exploration-of-awe-and-wonder/",
  },
  {
    title: "IOT",
    description:
      "From intelligent infrastructure to advanced industrial systems, our IoT expertise connects your devices for seamless efficiency. We design and implement custom IoT networks that transform data into actionable insights, enhancing productivity and user experiences. Let's build your connected future today.",
    about:
      "Integrate your devices with tailored IoT networks, transforming data into actionable insights for seamless efficiency.",
    src: [
      "./v2/services/iot_2.svg",
      "./v2/services/iot_1.svg",
      "./v2/services/iot_3.svg",
    ],
    color: "#000000",
    link: "https://www.ignant.com/2023/03/25/ad2186-matthias-leidingers-photographic-exploration-of-awe-and-wonder/",
  },
  {
    title: "Cloud Services",
    description:
      "Our cloud computing expertise optimizes your digital infrastructure for peak performance. We design, migrate, and manage custom cloud environments that boost efficiency, enhance security, and drive innovation. Let's elevate your operations to new heights with powerful cloud technology.",
    about:
      "Optimizing your digital infrastructure with powerful, secure, and efficient cloud solutions",
    src: [
      "./v2/services/aws.svg",
      "./v2/services/aws_2.svg",
      "./v2/services/aws_3.svg",
    ],
    color: "#333AFE",
    link: "https://www.ignant.com/2023/03/25/ad2186-matthias-leidingers-photographic-exploration-of-awe-and-wonder/",
  },
  {
    title: "Game Dev",
    description:
      "Our expert game development team turns visions into reality. From mobile apps to AAA titles, we create unforgettable experiences with cutting-edge tech, stunning graphics, and engaging gameplay. Web3 integration? We've got you covered.",
    about:
      "Bringing your game visions to life with cutting-edge tech, stunning graphics, and seamless Web3 integration",
    src: [
      "./v2/services/game_2.svg",
      "./v2/services/unity.svg",
      "./v2/services/game_3.svg",
    ],
    color: "#000000",
    link: "https://www.ignant.com/2023/03/25/ad2186-matthias-leidingers-photographic-exploration-of-awe-and-wonder/",
  },
  {
    title: "UI/UX/CX/SD",
    description:
      "Our design specialists craft intuitive interfaces and seamless journeys that improve every interaction with your brand. We blend visual appeal and practical function to enhance user satisfaction and drive conversions. Partner with us to create captivating experiences that engage and retain your audience, from first impression to lasting relationship.",
    about:
      "Crafting intuitive designs that captivate, engage, and convert from first impression to lasting relationship",
    src: [
      "./v2/services/ui_2.svg",
      "./v2/services/figma.svg",
      "./v2/services/ui_3.svg",
    ],
    color: "#333AFE",
    link: "https://www.ignant.com/2023/03/25/ad2186-matthias-leidingers-photographic-exploration-of-awe-and-wonder/",
  },
];
