import React from "react";

const Banner = () => {
  return (
    <div className="w-full bg-[url('/public/assets/grid.svg')] lg:min-h-[70vh] min-h-full mb-12 flex justify-center items-center overflow-hidden">
      <div className="my-12 md:my-6 relative py-20">
        {/* <img src="/assets/vector-about.svg" alt="" className="absolute top-0 right-0 md:-right-12 lg:w-fit w-1/6" /> */}
        {/* <img src="/assets/vector-about.svg" alt="" className="absolute bottom-0 left-0 w-1/6 lg:w-fit" /> */}
        <div className="flex flex-col items-center mx-6">
          <h1 className="lg:text-5xl xl:text-6xl md:text-4xl text-2xl text-center font-medium lg:mb-14 mb-6">
            Join us in shaping the future
          </h1>

          <a target="_blank" href="https://calendly.com/arnab-t4ml/30min">
            <button className="button shadow-[5px_10px_1px_rgba(32,_74,_248,_1),_0_10px_20px_rgba(0,_0,_0,_0)] mb-2">
              <span className="flex gap-3">
                Book a call
                <img src="/assets/calendar_clock.svg" alt="" />
              </span>
            </button>
          </a>
          {/* <div className="w-[80vw] rounded-lg">
            <iframe
              src="https://cal.com/rupeshwadne"
              width="100%"
              height="600"
            ></iframe>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Banner;
