import Marquee from "react-fast-marquee";

export default function Footer() {
  return (
    <div className="w-full flex flex-col items-center justify-around bg-primary-bg sm:pt-[1rem] sm:pb-[6rem] lg:pt-[2rem] lg:pb-[5rem] pt-[1rem] pb-[3rem]">
      <img src="./v2/symbols/logo.svg" className="sm:w-[10vw] w-[22vw]"></img>
      <a target="_blank" href="https://calendly.com/arnab-t4ml/30min">
        <button
          className="rounded-full lg:py-[1.25rem] py-[0.75rem] mt-[1rem] lg:px-[2rem] px-[1rem] bg-white/20 
         flex items-center justify-center space-x-2"
        >
          <img src="./v2/symbols/calendar.svg"></img>
          <p className="skBold">Schedule a call</p>
        </button>
      </a>

      <Marquee autoFill direction="left" pauseOnHover className=" w-full">
        <div className="flex items-center mr-[1rem]">
          <h1 className="sm:text-[5.5rem] text-[4.5rem] skBold text-white mr-[1rem] font-bold">
            METABORONG
          </h1>
          <img
            src="./v2/symbols/white-spin.svg"
            className="sm:w-[100%] w-[3.25rem]"
          ></img>
        </div>
        <div className="flex items-center mr-[1rem]">
          <h1 className="sm:text-[5.5rem] text-[4.5rem] skBold text-white mr-[1rem]  font-bold">
            METABORONG
          </h1>
          <img
            src="./v2/symbols/white-spin.svg"
            className="sm:w-[100%] w-[3.25rem]"
          ></img>
        </div>
        <div className="flex items-center mr-[1rem]">
          <h1 className="sm:text-[5.5rem] text-[4.5rem] skBold text-white mr-[1rem]  font-bold">
            METABORONG
          </h1>
          <img
            src="./v2/symbols/white-spin.svg"
            className="sm:w-[100%] w-[3.25rem]"
          ></img>
        </div>
      </Marquee>
    </div>
  );
}
