import { useEffect, useState } from "react";
import "./preloader.scss";
export default function Preloader() {
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
    }, 3500);
  }, []);
  return (
    <div
      className={`w-full h-screen bg-black overflow-hidden items-center justify-center z-50 fixed ${
        visible ? "flex" : "hidden"
      }`}
      id="preload"
    >
      <div className="w-fit h-fit overflow-hidden">
        <svg
          // width="81"
          // height="48"
          viewBox="0 0 81 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="text-animation opacity-0 w-[8rem] h-auto"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.179688 47.6899V29.6433C0.733706 26.6027 3.38819 24.2981 6.57927 24.2981H8.59499C8.59499 24.2981 12.4649 23.7716 14.0687 22.1179C15.6229 20.5152 16.1017 16.7677 16.1017 16.7677V8.94558C16.1017 4.50558 19.6898 0.90625 24.1159 0.90625C28.5419 0.90625 32.13 4.50558 32.13 8.94558V16.7677C32.13 16.7677 32.6088 20.5152 34.1631 22.1179C35.7668 23.7716 39.6367 24.2981 39.6367 24.2981H40.6516C40.6516 24.2981 44.5215 23.7716 46.1252 22.1179C47.4447 20.7572 47.9891 17.8508 48.1235 17.0039V8.94558C48.1235 4.50558 51.7115 0.90625 56.1376 0.90625C60.5637 0.90625 64.1517 4.50558 64.1517 8.94558V16.7677C64.1517 16.7677 64.6305 20.5152 66.1848 22.1179C67.7886 23.7716 71.6585 24.2981 71.6585 24.2981H73.6742C77.2673 24.2981 80.18 27.22 80.18 30.8243V47.6899H64.1517V24.2981H55.6302C55.6302 24.2981 51.7603 24.8245 50.1565 26.4782C48.837 27.8389 48.2926 30.7453 48.1583 31.5922V39.6505C48.1583 44.0905 44.5702 47.6899 40.1441 47.6899C35.7181 47.6899 32.13 44.0905 32.13 39.6505V31.8284C32.13 31.8284 31.6052 27.9463 29.9567 26.3375C28.3589 24.7783 24.6233 24.2981 24.6233 24.2981H16.1017V47.6899H0.179688Z"
            fill="white"
          />
        </svg>
      </div>
      <div className="absolute h-full w-full opacity-0 bg-secondary-bg right-0 slider">
        {/* <div className="w-[15%] h-full "></div> */}
      </div>
    </div>
  );
}
