import Marquee from "react-fast-marquee";
import { createRoot } from "react-dom/client";
import { clientsV2, feedback } from "../../../constants/works";
export default function Client() {
  return (
    <div
      id="clients"
      className=" bg-primary-bg h-fit w-full  lg:pb-[3rem] sm:pb-[8rem] pb-[3rem] lg:pt-[2.5rem] flex flex-col items-center lg:justify-between justify-center"
    >
      <div className="w-full flex flex-col items-center justify-between space-y-[1.5rem] sm:space-y-[2.25rem] py-[1.5rem]">
        <h1 className="skRegular w-full lg:text-3xl sm:text-2xl text-lg text-left leading-[1] lg:px-[2.5rem] px-[0.75rem] ">
          {/* Our Global{" "} */}
          {/* <span className="">
            <img
              src="./v2/symbols/globe.png"
              className="inline m-0 align-middle"
            ></img>
          </span> */}
          <br />
          <span className="skBold lg:text-6xl sm:text-5xl text-3xl">
            Clients & Partners
          </span>
        </h1>
        <div className="w-full flex items-center justify-center space-y-[1rem] flex-col">
          <Marquee autoFill direction="right" pauseOnHover>
            {clientsV2.map((el) => (
              <div className="flex items-center justify-start mr-[2rem] ">
                <img src={el.source} className="mr-[0.5rem]"></img>
                <h1 className="neueRegular">{el.name}</h1>
                <img
                  src="/assets/hero-star-blue.svg"
                  className="ml-[2rem]"
                ></img>
              </div>
            ))}
          </Marquee>
          <Marquee autoFill direction="left" pauseOnHover>
            {clientsV2.map((el) => (
              <div className="flex items-center justify-start mr-[2rem] ">
                <img src={el.source} className="mr-[0.5rem]"></img>
                <h1 className="neueRegular">{el.name}</h1>
                <img
                  src="/assets/hero-star-blue.svg"
                  className="ml-[2rem]"
                ></img>
              </div>
            ))}
          </Marquee>
          <Marquee autoFill direction="right" pauseOnHover>
            {clientsV2.reverse().map((el) => (
              <div className="flex items-center justify-start mr-[2rem] ">
                <img src={el.source} className="mr-[0.5rem]"></img>
                <h1 className="neueRegular">{el.name}</h1>
                <img
                  src="/assets/hero-star-blue.svg"
                  className="ml-[2rem]"
                ></img>
              </div>
            ))}
          </Marquee>
        </div>
        <div className="w-full">
          <Marquee>
            {feedback.map((el) => (
              <FeedbackCard el={el} />
            ))}
          </Marquee>
        </div>
      </div>
    </div>
  );
}

function FeedbackCard({ el }) {
  // const descContainer = document.getElementById("desc");
  // const descRoot = createRoot(descContainer);
  return (
    <div className="sm:w-[30rem] sm:h-[19rem] w-[20rem] h-[18rem] relative sm:mr-[3rem] mr-[1.5rem] border-[1px] bg-black border-white/20 rounded-2xl flex flex-col items-start justify-around sm:p-[1.75rem] p-[0.75rem] ">
      <div className="flex items-center justify-between w-full mb-[1rem] ">
        <div className="flex items-start space-x-2 justify-center w-fit h-fit">
          <div className="w-[3rem] h-[3rem] rounded-full">
            <img
              src={el.pic}
              className="object-fit h-full w-full rounded-full"
            ></img>
          </div>
          <div className="flex flex-col items-start justify-center ">
            <h1>{el.name}</h1>
            <h2 className="text-white/60">{el.id}</h2>
          </div>
          <img src="./v2/clients/feedback/check.svg"></img>
        </div>
        <img src="./v2/clients/feedback/x.svg"></img>
      </div>
      <p
        className="sm:text-xl text-base skRegular sm:mb-[1.5rem] mb-[0.75rem]"
        id="desc"
      >
        {el.desc}
      </p>
      <div className="neueRegular text-white/40 flex items-center justify-start space-x-[0.75rem] w-full pb-[0.5rem] border-b-[1px] border-b-white/20 text-sm">
        <p className="flex items-center justify-center">
          {el.time} <span className="mb-[0.5rem] ml-[0.75rem]">.</span>{" "}
        </p>
        <p className="flex items-center justify-center ">
          {el.date}
          <span className="mb-[0.5rem] ml-[0.75rem]">.</span>{" "}
        </p>
        <p>
          <span className="text-white mr-[0.2rem]">{el.view}</span> views
        </p>
      </div>
      <div className="neueRegular text-white/40 flex items-center justify-start space-x-[1rem] mt-[0.5rem] text-sm">
        <p className="flex items-center justify-center">
          <span className="text-white mr-[0.25rem]">{el.repost}</span> Reposts
          <span className=" mx-[0.5rem] mb-[0.5rem] ">.</span>
          <span className="text-white mr-[0.25rem]">{el.comment}</span> Comments
        </p>
      </div>
    </div>
  );
}
