import React from "react";
import { useState } from "react";
import GetQuote from "../components/GetQuote";
import { useQuoteState } from "../context/Quote";

const Banner2 = () => {
  const { quote, setQuote } = useQuoteState();

  return (
    <div className="w-full bg-[url('/public/assets/grid.svg')] md:min-h-[70vh] min-h-[50vh] flex justify-center items-center relative">
      {/* <img src="/assets/vector-about.svg" alt="" className="absolute xl:top-24 md:top-12 top-0 md:w-fit w-1/6 lg:right-12 xl:right-44 right-0" /> */}
      {/* <img src="/assets/vector-about.svg" alt="" className="absolute md:w-fit w-1/6 lg:bottom-24 md:bottom-12 bottom-16 xl:left-44 md:left-16 left-0" /> */}
      <div className="flex flex-col items-center mx-4">
        <h1 className="lg:text-5xl md:text-4xl text-3xl text-center font-medium lg:mb-6 mb-2">
          Be part of our next success story
        </h1>

        <p className="md:text-xl xl:text-[21px] text-lg text-center md:mb-6 mb-3">
          Share about your ideas & collaborate with us
        </p>

        <button
          onClick={() => setQuote(!quote)}
          className="button shadow-[5px_10px_1px_rgba(32,_74,_248,_1),_0_10px_20px_rgba(0,_0,_0,_0)] mb-2"
        >
          <span className="flex gap-3">
            Get a quote
            <img src="/assets/calendar_clock.svg" alt="" />
          </span>
        </button>
      </div>

      {quote ? <GetQuote /> : ""}
    </div>
  );
};

export default Banner2;
