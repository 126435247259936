import React, { useEffect, useRef } from "react";
import Marquee from "react-fast-marquee";
import { projects } from "../../constants/services";
import Card from "./card/Card";
import { useScroll } from "framer-motion";
import Lenis from "@studio-freight/lenis";

const domains = [
  "WEB3.0",
  "WEB2.0",
  "AI/ML",
  "IOT",
  "SAAS",
  "UI/UX/CX/SD",
  "CLOUD",
  "PRODUCT",
];

const ServicesSection = () => {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end end"],
  });

  useEffect(() => {
    const lenis = new Lenis({
      smooth: true, // Enable smooth scrolling
      wrapper: containerRef.current, // Attach Lenis to the scroll container
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    return () => {
      lenis.destroy(); // Clean up Lenis instance on component unmount
      cancelAnimationFrame(raf); // Clean up requestAnimationFrame
    };
  }, []);

  return (
    <div
      id="services"
      className="bg-primary-bg h-full w-full xl:px-[2.5rem] px-4 pt-[2.5rem] lg:pb-[1.5rem] flex flex-col items-center justify-between pb-[3rem]"
    >
      <div className="h-full w-full flex flex-col items-center text-center font-normal">
        <p className="lg:text-3xl sm:text-2xl text-lg text-[#A6A9FF] w-full text-left">
          We have <span className="text-white">specialization</span> in
        </p>
        <h1 className="lg:text-6xl sm:text-5xl text-3xl w-full text-left">
          Diverse Domains
        </h1>
        {/* <Marquee
          className="bg-[#333AFE] mt-8 px-4 py-4 rounded-xl"
          autoFill
          direction="right"
        >
          {domains.map((el, i) => (
            <div className="text-lg flex gap-3 ml-3" key={i}>
              <img src="/assets/marquee-svg.svg" alt="" />
              {el}
            </div>
          ))}
        </Marquee> */}
        <div
          className="sm:h-[490px] h-[490px] w-full pt-4 overflow-x-hidden overflow-y-scroll"
          ref={containerRef}
        >
          <main>
            {projects.map((project, i) => {
              const targetScale = 1 - (projects.length - i) * 0.02;

              return (
                <Card
                  key={`p_${i}`}
                  i={i}
                  {...project}
                  progress={scrollYProgress}
                  range={[i * 0.25, 1]}
                  targetScale={targetScale}
                  logo={project.src}
                  color={project.color}
                />
              );
            })}
          </main>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
