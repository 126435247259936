import { useRef } from "react";
import { works } from "./data";
import Marquee from "react-fast-marquee";

const Carousel = () => {
  const carouselRef = useRef(null);

  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: -340, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: 340, behavior: "smooth" });
    }
  };

  return (
    <div className="relative mt-8 w-full flex flex-col gap-6 items-end">
      <div
        ref={carouselRef}
        className="flex overflow-x-scroll scroll-smooth space-x-4 scrollbar-hide w-full"
      >
        {/* Cards */}
        {works.map((el, index) => (
          <div
            key={index}
            className="lg:min-w-[20vw] md:min-w-[30vw] sm:min-w-[30vw] min-w-[60vw] relative"
          >
            <img src={el.img} alt="" className="w-full" />
            <div className="relative mt-2">
              <img
                src="/assets/clients/workcard1.svg"
                className="w-full"
                alt=""
              />
              <div className="absolute top-3 left-3 flex flex-col items-start text-start space-y-2 sm:space-y-1 md:space-y-3 md:top-5 md:left-5 lg:text-[0.9rem] md:text-sm text-xs text-white/50">
                <p className="lg:text-[24px] md:text-[1.6rem] text-[1.3rem] sm:text-[1.25rem] text-white">
                  {el.brief}
                </p>
                <span>{el.description}</span>
              </div>
              <a href={el.link}>
                <img
                  src="/assets/clients/arrow-up-right.svg"
                  alt=""
                  className="absolute bottom-3 left-3 md:w-8 md:h-8  w-5 h-5"
                />
              </a>
              <div className="absolute bottom-0 right-0 w-[47%]">
                <Marquee
                  className="bg-[#333AFE] mt-8 px-4 lg:py-3 md:py-[16px] sm:py-[0.4rem] py-[0.5rem] md:rounded-2xl rounded-xl"
                  autoFill
                  direction="right"
                >
                  {el.category.map((el, i) => (
                    <div
                      className="lg:text-lg md:text-base sm:text-sm text-xs flex items-center gap-3 ml-3"
                      key={i}
                    >
                      <img
                        src="/assets/marquee-svg.svg"
                        alt=""
                        className="lg:w-fit md:w-6"
                      />
                      {el}
                    </div>
                  ))}
                </Marquee>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex items-center ">
        {/* Left Button */}
        <button
          onClick={scrollLeft}
          className="  bg-[#000500] border-[1px] border-white text-white lg:w-12 w-10 lg:h-12 h-10 rounded-full text-xl mr-4"
        >
          ←
        </button>
        {/* Right Button */}
        <button
          onClick={scrollRight}
          className="bg-[#000500] border-[1px] border-white text-white lg:w-12 w-10 lg:h-12 h-10 rounded-full text-xl"
        >
          →
        </button>
      </div>
    </div>
  );
};

export default Carousel;
