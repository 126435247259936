import Carousel from "./Carousel";

export default function Works() {
  return (
    <div
      id="works"
      className="h-fit w-full lg:px-[2.5rem] px-[0.75rem] pt-[2.5rem] pb-[5.5rem] flex flex-col items-center justify-between"
    >
      <div className="h-full w-full flex flex-col items-center text-center font-normal">
        <p className="lg:text-6xl sm:text-5xl text-3xl text-offwhite text-left skRegular w-full">
          Our Works
        </p>
        <h1 className="lg:text-3xl sm:text-2xl text-lg w-full text-left skBold text-white">
          Proof of Commitments and Producivity
        </h1>
        <Carousel />
      </div>
    </div>
  );
}
