"use client";
import { useTransform, motion } from "framer-motion";
import styles from "./style.module.scss";
import { SubCard } from "./SubCard";

const Card = ({ title, description, i, logo }) => {
  return (
    <div className={styles.cardContainer}>
      <motion.div
        className={styles.card}
        style={{ top: `calc(0vh + ${i * 1.5}vh)` }}
      >
        <div
          className={`w-full rounded-3xl ${
            i % 2 === 0 ? "bg-[#4D6EF9]" : "bg-black "
          } flex flex-col sm:flex-row items-center justify-center sm:justify-between sm:pl-12 sm:px-0 px-3 sm:pt-0 pt-4 h-fit sm:h-full`}
        >
          <div className="w-fit flex flex-col items-start gap-4">
            <span className="flex gap-5">
              <h1 className="font-normal lg:text-5xl xl:text-6xl md:text-4xl text-3xl sm:text-5xl text-start">
                {title}
              </h1>
              <img
                src="/assets/domain.svg"
                alt=""
                className="animate-spin xl:w-fit sm:w-10 w-8"
              />
            </span>
            <p className="font-light xl:text-xl sm:text-base text-xs text-start">
              {description}
            </p>
            <a href="mailto:arnab@metaborong.com">
              <button
                className={`flex gap-2 ${
                  i % 2 === 0 ? "bg-black text-white" : "bg-white text-black"
                } text-xs sm:text-base font-normal sm:py-4 sm:px-6 py-2 px-2 rounded-full`}
              >
                <img
                  src="/assets/mail-at-sign.svg"
                  className={`${
                    i % 2 === 0 ? "invert-0" : "invert"
                  } w-4 h-4 sm:w-fit sm:h-fit`}
                  alt=""
                />{" "}
                Drop a Mail
              </button>
            </a>
          </div>
          <div className="w-full">
            <SubCard logo={logo} i={i} />
          </div>
          <div className="w-full justify-center sm:hidden flex">
            <img
              src={
                i % 2 === 0
                  ? "./v2/services/service-start-1.svg"
                  : "./v2/services/service-start-2.svg"
              }
              alt=""
              className="w-6 h-6 mb-4"
            />
          </div>
        </div>
        <img
          src={
            i % 2 === 0
              ? "./v2/services/service-start-1.svg"
              : "./v2/services/service-start-2.svg"
          }
          alt=""
          className="w-10 h-10 absolute bottom-4 right-4 sm:flex hidden"
        />
      </motion.div>
    </div>
  );
};

export default Card;
