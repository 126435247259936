export const works = [
    {
        brief: "Full Stack App Dev",
        description: "Restaurant Queue & Booking Management App for Juump",
        link: "/",
        category: ["WEB 3.0"],
        img: "/assets/clients/jumpin.svg"
    },
    {
        brief: "NFT Building",
        description: "Collaboration with Pluto",
        link: "/",
        category: ["WEB 3.0"],
        img: "/assets/clients/pluto.svg"
    },
    {
        brief: "E2E Web 3.0 Dev",
        description: "Collaboration with Bionic",
        link: "/",
        category: ["WEB 3.0"],
        img: "/assets/clients/bionic.svg"
    },
    {
        brief: "Blockchain, Backend",
        description: "Building Database as a Service for Kandola Network",
        link: "/",
        category: ["WEB 2.0","WEB 3.0"],
        img: "/assets/clients/kandola.svg"
    },
    {
        brief: "Infrastructure Dev",
        description: "Built backend infrastructure for PredictRAM",
        link: "/",
        category: ["FINTECH"],
        img: "/assets/clients/predict.svg"
    },
    {
        brief: "Blockchain Dev",
        description: "Blockchain development for KGEN games",
        link: "/",
        category: ["WEB 3.0", "GAMING"],
        img: "/assets/clients/kgen.svg"
    },
    {
        brief: "Gen AI link Web 3.0 ",
        description: "Partnering Create Protocol for Blockchain integration with AI/ML",
        link: "/",
        category: ["AI/ML", "WEB 3.0"],
        img: "/assets/clients/create.svg"
    },
    {
        brief: "SAAS platform Dev",
        description: "E-Commerce SAAS product for startup acquisition",
        link: "/",
        category: ["SAAS", "E-COMMERCE"],
        img: "/assets/clients/scoop-work.svg"
    },
    {
        brief: "NFT Building",
        description: "Collaboration with Passengers",
        link: "/",
        category: ["WEB 3.0"],
        img: "/assets/clients/passenger.svg"
    },
    {
        brief: "Game, Backend Dev",
        description: "Collaboration with Circle of Games",
        link: "/",
        category: ["WEB 3.0", "GAMING"],
        img: "/assets/clients/COG.svg"
    },
    {
        brief: "Full stack Web 3.0",
        description: "Full stack web 3.0 dev in fintech for Defiverse",
        link: "/",
        category: ["WEB 3.0", "FINTECH"],
        img: "/assets/clients/defiverse.svg"
    },
]