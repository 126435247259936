import React, { useState, useEffect } from "react";
import { services } from "../../../constants/services";
import { Boxes } from "../../ui/background-boxes";
import { worksV2 } from "../../../constants/works";
import Marquee from "react-fast-marquee";
import Navbar from "../../v2/Navbar";
import { SubCard } from "./SubCard";
import { projects } from "../../../constants/services";
// import BackgroundGradient from "../components/BackgroundGradient"

const engagements = [
  "/assets/aggregation-summit.svg",
  "/assets/DEVCON.svg",
  "/assets/ETHSEA.svg",
  "/assets/EEA.svg",
  "/assets/settlement-day.svg",
  "/assets/sheFI.svg",
  "/assets/Web3Privacy.svg",
  "/assets/ETHGlobal.svg",
  "/assets/ETHInteropForum.svg",
  "/assets/banklessSummit.svg",
  "/assets/IBW2024.svg",
  "/assets/ETHINDIA.svg",
];

const NewHome = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex === projects.length) {
      setCurrentIndex(0);
    }
    const interval = setInterval(() => {
      const updatedData = currentIndex + 1;
      setCurrentIndex(updatedData);
    }, 3500);

    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <div className="lg:h-screen xl:h-full h-full w-screen xl:w-full flex md:flex-row flex-col overflow-x-hidden">
      <div className="md:w-[60%] w-screen sm:h-screen h-[50vh] bg-secondary-bg relative overflow-hidden">
        <div className="sm:top-5 top-3 sm:left-10 left-4 absolute sm:w-[8%] sm:h-[8%] w-full flex items-center justify-start lg:space-x-6 md:space-x-4 space-x-2">
          <img src="./v2/logo.svg" className="sm:h-32 sm:w-32 h-16 w-16"></img>
          <p className="lg:text-3xl md:text-xl sm:text-base text-xl w-full object-contain neueRegular">
            Metaborong
          </p>
        </div>
        <img
          src="/career/bg-frame.svg"
          alt=""
          className="xl:top-40 lg:top-48 md:top-36 top-24 md:left-24 absolute md:w-fit w-full md:h-fit h-2/3 md:object-none object-cover"
        />

        <div className="xl:ml-32 lg:ml-24 md:ml-16 ml-5 absolute top-[30%] md:top-[40%] w-screen lg:w-[90%]">
          <div className="relative xl:w-fit lg:w-full">
            {/* <img src="/assets/hero-vector.png" alt="" className="absolute md:-top-12 -top-8 w-1/6 md:w-fit md:right-2 left-3/4" /> */}

            <div className="lg:text-7xl md:text-6xl sm:text-[5rem] text-[2.5rem] lg:w-fit w-5/6 gap-1 sm:gap-0 flex flex-col font-normal tracking-tight sm:tracking-normal">
              <div className="flex items-center sm:gap-5 gap-3">
                <p>Your Perfect</p>
                <img
                  src="/assets/domain.svg"
                  alt=""
                  className="animate-spin sm:w-fit w-9"
                />
              </div>

              <p>Partner in Tech</p>
            </div>

            <div className="flex gap-4 mt-8 text-lg md:text-2xl relative">
              <a target="_blank" href="https://calendly.com/arnab-t4ml/30min">
                <button className="rounded-full lg:py-[1.25rem] md:py-[0.5rem] lg:px-[2rem] md:px-[1rem] py-[1rem] px-[1.5rem] bg-black flex items-center justify-center space-x-2">
                  <img src="./v2/symbols/calendar.svg"></img>
                  <p className="skBold">Schedule a call</p>
                </button>
              </a>
            </div>
          </div>
        </div>
        {/* <Grid /> */}
        {/* <Boxes /> */}
      </div>

      <div className="md:w-[40%] md:h-full w-screen overflow-x-hidden bg-hero bg-cover grid grid-rows-3 gap-8 lg:pt-20 pt-10 pb-12 lg:px-8 px-4 bg-hero-bg">
        <div className="relative border border-card-bg-3 rounded-2xl bg-card-bg-1 w-full">
          <div className="flex gap-2 items-center justify-between w-full px-4 mt-2">
            <div className="flex gap-2 items-center">
              <p className="lg:text-[2.5rem] md:text-[3.5rem] text-6xl text-lemon neueRegular">
                20+
              </p>
              <img src="/assets/hero-star.svg"></img>
              <p className="md:text-[18px] md:leading-[20px] text-base md:w-[4.5rem] w-[4rem] text-center neueRegular">
                Trusted clients
              </p>
            </div>
            <div>
              <img
                src="/assets/clients/arrow-up-right.svg"
                className="w-8 h-8"
                alt=""
              />
            </div>
          </div>
          <div className="absolute md:bottom-0 bottom-3 right-0 w-full px-4">
            <Marquee
              pauseOnHover
              className="mt-8 px-4 lg:py-4 md:py-[6px] rounded-2xl"
              autoFill
              direction="right"
            >
              {worksV2.map((el, i) => (
                <Card source={el.source} />
              ))}
            </Marquee>
          </div>
        </div>

        <div className="flex gap-6 h-fit">
          <div className="rounded-2xl relative flex w-2/3 md:px-6 xl:px-2 lg:px-4 px-2 h-fit">
            <div className="bg-card-bg-1 border border-card-bg-3 rounded-2xl absolute top-0 left-0 right-0 mx-auto w-[80%] sm:h-[130px] h-[90px]"></div>
            <div className="bg-card-bg-2 border border-card-bg-3 rounded-2xl absolute top-[10px] left-0 right-0 mx-auto w-[90%] sm:h-[130px] h-[90px]"></div>
            <div className="bg-[#262942] border border-card-bg-3 rounded-2xl absolute top-[20px] left-0 right-0 mx-auto w-[100%] sm:h-[140px] h-[110px] flex py-3 justify-start gap-4 px-3 overflow-hidden lg:text-xl md:text-lg sm:text-2xl text-base font-medium">
              <p>
                {/* Recent engagements */}
                Upcoming engagements
              </p>

              <div className="absolute bottom-0 right-0 lg:w-full px-4">
                <Marquee
                  className="px-4 lg:py-4 py-[6px] rounded-2xl"
                  autoFill
                  direction="right"
                >
                  {engagements.map((engagement) => {
                    return (
                      <img
                        src={engagement}
                        alt=""
                        className="mr-4 w-auto h-auto object-contain aspect-square"
                      />
                    );
                  })}
                  {/* <img src="/assets/webx.svg" alt="" className="mr-4 md:w-28 sm:w-32 w-24" />
                  <img src="/assets/ethtokyo.svg" alt="" className="mr-4 md:w-28 sm:w-32 w-24" /> */}
                </Marquee>
              </div>
            </div>
          </div>
          <div className="rounded-2xl bg-card-bg-2 w-1/3 sm:h-[160px] h-[130px] relative flex justify-center items-center">
            <img
              src="/assets/circular_text.svg"
              alt=""
              className="absolute top-0 left-0 my-auto mx-auto w-full h-full p-4 animate-spinSlow"
            />
            <img src="/assets/arrow.svg" alt="" className="w-1/4 h-1/4" />
          </div>
        </div>
        <div className="overflow-x-hidden flex flex-col sm:flex-row sm:justify-between justify-center items-center px-4 rounded-2xl bg-card-bg-1 h-[220px] sm:h-[210px] md:h-[200px] sm:-mt-6 -mt-12 box-border z-0 overflow-hidden">
          <div className="w-fit flex flex-col items-start gap-2 py-2">
            <span className="flex gap-3">
              <h1 className="font-normal lg:text-[33px] md:text-2xl text-3xl leading-9">
                {projects[currentIndex]?.title}
              </h1>
              <img
                src="/assets/domain.svg"
                alt=""
                className="animate-spin lg:w-8 sm:w-6 w-8"
              />
            </span>
            <p className="font-light md:text-sm sm:text-base text-sm lg:text-sm text-start">
              {projects[currentIndex]?.about}
            </p>
          </div>
          <div className="w-full border-red-50">
            <SubCard logo={projects[currentIndex]?.src} />
          </div>
          {/* <div
          className={`w-full rounded-l-3xl rounded-tr-3xl bg-[#333AFE] flex items-center justify-between pl-12`}
        >
          <div className="w-fit flex flex-col items-start gap-4">
            <span className="flex gap-5">
              <h1 className="font-normal lg:text-5xl xl:text-6xl md:text-4xl">
                {projects[0].title}
              </h1>
              <img
                src="/assets/domain.svg"
                alt=""
                className="animate-spin xl:w-fit md:w-10"
              />
            </span>
            <p className="font-light xl:text-xl md:text-base text-start">
              {projects[0].description}
            </p>
          </div>
          <div className="w-full">
            <SubCard logo={projects[0].src} />
          </div>
        </div> */}
        </div>
      </div>
      <Navbar />
    </div>
  );
};

export default NewHome;

function Card({ source }) {
  return (
    <div className="lg:w-[4.5rem] md:w-[4rem] w-[4rem] lg:mr-5 mr-3">
      <img src={source}></img>
    </div>
  );
}
