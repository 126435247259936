import React from "react";
import { motion } from "framer-motion";
import createGlobe from "cobe";
import { useEffect, useRef } from "react";

const About = () => {
  return (
    <div
      id="about"
      className="w-full lg:px-[2.5rem] px-[0.75rem] pt-[2.5rem] md:mx-10 mx-4 md:mt-24 mt-14 mb-14"
    >
      <div className="flex justify-between md:flex-row flex-col items-center md:items-start">
        <div className="xl:text-7xl lg:text-5xl md:text-5xl text-3xl leading-[1.10] md:w-4/5 lg:w-3/5 w-full relative">
          <h1 className="w-full font-normal">
            We are the visionary builders you need
          </h1>
          {/* <img
            src="/assets/vector-about.svg"
            alt=""
            className="absolute -top-6 lg:right-4 right-2 lg:w-fit mg:w-1/5 w-1/6"
          /> */}
        </div>

        <a href="mailto:Supriya@metaborong.com">
          <button className="border-2 border-offwhite delay-100 duration-100 rounded-full lg:px-16 px-8 py-3 lg:text-2xl font-normal mt-6 hover:invert hover:bg-black">
            Let's talk
          </button>
        </a>
      </div>
      <div className="flex lg:flex-row flex-col gap-8 my-10">
        <motion.div
          whileHover={{
            scale: 1.05,
            backgroundColor: "#1C6DD0",
          }}
          transition={{ duration: 0.5, yoyo: Infinity }}
          className="bg-secondary-bg lg:w-[60%] rounded-3xl pb-12 md:p-14 lg:p-9 xl:p-14 p-6 relative"
        >
          <h1 className="md:text-2xl xl:text-2xl lg:text-xl text-xl w-[88%]">
            Established in 2022, Metaborong Technologies Pvt Ltd. provides
            top-tier digital infrastructure solutions, focusing on scalability
            and security to meet the highest standards of excellence.
          </h1>
          <img
            src="/assets/wing.png"
            className="absolute bottom-0 lg:w-1/6 xl:w-fit"
            alt=""
          />
          <img
            src="/assets/south_east.svg"
            className="absolute md:bottom-3 bottom-1 right-5 w-1/6 md:w-fit"
            alt=""
          />
        </motion.div>
        <div className="flex justify-center lg:w-1/2 w-full md:gap-8 gap-4">
          <motion.div
            whileHover={{
              scale: 1.05,
              backgroundColor: "#1C6DD0",
            }}
            transition={{ duration: 0.5, yoyo: Infinity }}
            className="bg-secondary-bg md:w-[65%] w-1/2 rounded-3xl justify-center relative flex flex-col items-center text-xl"
          >
            <img src="/assets/global.svg" className="animate-spinSlow" alt="" />
          </motion.div>
          <motion.div
            whileHover={{
              scale: 1.05,
              backgroundColor: "#1C6DD0",
            }}
            transition={{ duration: 0.5, yoyo: Infinity }}
            className="rounded-2xl bg-secondary-bg flex justify-center items-center p-16 md:p-0 md:w-[30%] lg:w-1/2 w-1/2"
          >
            <img
              src="/assets/circular_text.svg"
              alt=""
              className="absolute animate-spinSlow"
            />
            <img src="/assets/arrow.svg" alt="" className="" />
          </motion.div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="xl:text-7xl lg:text-5xl md:text-5xl text-4xl leading-[1.10] w-3/5 relative mb-8 font-normal">
          <h1>Our values</h1>
        </div>

        <div className="flex md:flex-row flex-col justify-between gap-4">
          <motion.div
            whileHover={{
              translateX: -10,
              translateY: -8,
            }}
            transition={{ duration: 0.2, yoyo: Infinity }}
            className="md:w-1/3 border border-card-bg-3 flex rounded-2xl bg-card-bg-1 justify-center hover:shadow-[8px_8px_0px_0px_rgba(255,255,255)] duration-100"
          >
            <div className="w-5/6 m-4 flex flex-col">
              <div className="flex flex-col">
                <h1 className="xl:text-[32px] text-[25px] xl:w-full leading-snug font-light">
                  Unleashing excellence
                </h1>
                <h1 className="xl:text-[14px] text-base md:mb-5 mt-2 mb-3 xl:w-full leading-snug">
                  Elevating IT solutions, exceeding expectations.
                </h1>
              </div>
              <img
                src="/assets/line.svg"
                alt=""
                className="md:mb-5 mb-2 opacity-60"
              />
              <p className="text-base opacity-60">
                In a dynamic technology landscape, we strive for excellence at
                every turn. With relentless dedication, we craft high-quality IT
                solutions that not only meet but transcend our client's
                expectations. We continuously seek opportunities to innovate,
                improve, and stay ahead,empowering business.
              </p>
            </div>
          </motion.div>

          <motion.div
            whileHover={{
              translateX: -10,
              translateY: -8,
            }}
            transition={{ duration: 0.2, yoyo: Infinity }}
            className="md:w-1/3 border border-card-bg-3 flex rounded-2xl bg-card-bg-1 justify-center hover:shadow-[8px_8px_0px_0px_rgba(255,255,255)] duration-100"
          >
            <div className="w-5/6 m-4 flex flex-col">
              <div className="flex flex-col">
                <h1 className="xl:text-[32px] font-light text-[25px] xl:w-full leading-snug">
                  Integrity
                </h1>
                <h1 className="xl:text-[14px] text-base md:mb-5 mt-2 mb-3 xl:w-full leading-snug">
                  Our foundation, your assurance
                </h1>
              </div>
              <img
                src="/assets/line.svg"
                alt=""
                className="md:mb-5 mb-2 opacity-60"
              />
              <p className="text-base opacity-60">
                We operate with unwavering honesty, transparency, and ethical
                practices to build a strong foundation of trust. We safeguard
                our client's sensitive data, ensuring absolute confidentiality.
                By putting integrity first, we nurture enduring relationship
                fueled by mutual confidence and shared success.
              </p>
            </div>
          </motion.div>

          <motion.div
            whileHover={{
              translateX: -10,
              translateY: -8,
            }}
            transition={{ duration: 0.2, yoyo: Infinity }}
            className="md:w-1/3 border border-card-bg-3 flex rounded-2xl bg-card-bg-1 justify-center hover:shadow-[8px_8px_0px_0px_rgba(255,255,255)] duration-100"
          >
            <div className="w-5/6 m-4 flex flex-col">
              <div className="flex flex-col">
                <h1 className="xl:text-[32px] font-light text-[25px] xl:w-full leading-snug">
                  Collaboration
                </h1>
                <h1 className="xl:text-[14px] text-base md:mb-5 mt-2 mb-3 xl:w-full leading-snug">
                  Harnessing collective genius for unparalleled results
                </h1>
              </div>
              <img
                src="/assets/line.svg"
                alt=""
                className="md:mb-5 mb-2 opacity-60"
              />
              <p className="text-base opacity-60">
                We believe in the transformative power of collaboration. By
                harnessing diverse perspective and combining expertise, we
                unlock extraordinary outcomes. Through active listening, open
                communication, and close collaboration with our clients, we gain
                profound insights into their unique needs. This allows us to
                craft custom IT solutions that fuel innovation, enhance
                efficiency, and unlock untapped potential.
              </p>
            </div>
          </motion.div>
        </div>
      </div>
      <div className="w-full mt-[1rem] py-[2rem] lg:h-[100vh] h-[68vh] border border-card-bg-3 flex rounded-2xl bg-card-bg-1  relative overflow-hidden">
        <img
          src="./career/bg-frame.svg"
          className="absolute lg:-left-[23.5%] -left-[40%] top-[12%] hidden sm:flex"
        ></img>
        <h1
          className="sm:text-3xl text-lg font-medium leading-relaxed w-full text-center text-gray-400
"
        >
          Making <span className="text-white">global footprints</span> <br />
          <span className="sm:text-5xl text-2xl">
            with every <span className="text-white">Innovation</span>
          </span>
        </h1>
        <img
          src="./career/bg-frame.svg"
          className="absolute lg:-right-[23.5%] -right-[40%] top-[12%] hidden sm:flex"
        ></img>
        <div className="absolute left-1/2 -translate-x-1/2 my-auto w-[40rem] h-[50rem] top-[16%] ">
          <div className="relative w-full h-full">
            <SkeletonFour />
          </div>
        </div>
      </div>
      {/* <div className="md:m-20 mt-16 flex flex-col items-center relative w-full h-full"> */}
      {/* <div className="relative">
          <img src="/assets/map-base.svg" alt="" className="w-fit" />

          <div className="absolute top-[0%] left-[42%] w-[16px] h-[16px] md:w-[23px] lg:w-[33px] xl:w-[45px] md:h-[23px] lg:h-[33px] xl:h-[45px]">
            <span class="animate-ping absolute md:inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
            <img
              src="/assets/1.svg"
              alt=""
              className="animate-pulse md:w-full w-full"
            />
          </div>

          <div className="absolute top-[40%] left-[40%] w-[16px] h-[16px] md:w-[23px] lg:w-[33px] xl:w-[45px] md:h-[23px] lg:h-[33px] xl:h-[45px]">
            <span class="animate-ping absolute md:inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
            <img
              src="/assets/1.svg"
              alt=""
              className="animate-pulse md:w-full w-full"
            />
          </div>

          <div className="absolute top-[20%] left-[14.5%] w-[16px] h-[16px] md:w-[23px] lg:w-[33px] xl:w-[45px] md:h-[23px] lg:h-[33px] xl:h-[45px]">
            <span class="animate-ping absolute md:inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
            <img
              src="/assets/1.svg"
              alt=""
              className="animate-pulse md:w-full w-full"
            />
          </div>

          <div className="absolute top-[30%] right-[33.7%] w-[16px] h-[16px] md:w-[23px] lg:w-[33px] xl:w-[45px] md:h-[23px] lg:h-[33px] xl:h-[45px]">
            <span class="animate-ping absolute md:inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
            <img
              src="/assets/1.svg"
              alt=""
              className="animate-pulse md:w-full w-full"
            />
          </div>

          <div className="absolute top-[36%] right-[24%] w-[16px] h-[16px] md:w-[23px] lg:w-[33px] xl:w-[45px] md:h-[23px] lg:h-[33px] xl:h-[45px]">
            <span class="animate-ping absolute md:inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
            <img
              src="/assets/1.svg"
              alt=""
              className="animate-pulse md:w-full w-full"
            />
          </div>

          <div className="absolute top-[18%] right-[19%] w-[16px] h-[16px] md:w-[23px] lg:w-[33px] xl:w-[45px] md:h-[23px] lg:h-[33px] xl:h-[45px]">
            <span class="animate-ping absolute md:inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
            <img
              src="/assets/1.svg"
              alt=""
              className="animate-pulse md:w-full w-full"
            />
          </div>
        </div> */}
      {/* <div className="lg:w-3/5 xl:w-2/5 md:w-full text-center flex justify-center m-4 md:h-44">
        <div className="flex-col flex justify-center w-screen"></div>
      </div>
    </div> */}{" "}
    </div>
  );
};

export const SkeletonFour = () => {
  return (
    <div className="h-60  flex flex-col items-start justify-start relative  ">
      <Globe className="right-0 relative top-0 md:-right-10 -bottom-80 md:-bottom-72" />
    </div>
  );
};

export const Globe = ({ className }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    let phi = 0;

    if (!canvasRef.current) return;

    const globe = createGlobe(canvasRef.current, {
      devicePixelRatio: 2,
      width: 600 * 2,
      height: 600 * 2,
      phi: 0,
      theta: 0,
      dark: 1,
      diffuse: 1.2,
      mapSamples: 16000,
      mapBrightness: 6,
      baseColor: [0.3, 0.3, 0.3],
      markerColor: [0.1, 0.8, 1],
      glowColor: [1, 1, 1],
      markers: [
        // longitude latitude
        { location: [37.7595, -122.4367], size: 0.03 },
        { location: [40.7128, -74.006], size: 0.1 },
      ],
      onRender: (state) => {
        // Called on every animation frame.
        // `state` will be an empty object, return updated params.
        state.phi = phi;
        phi += 0.01;
      },
    });

    return () => {
      globe.destroy();
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{ width: 600, height: 600, maxWidth: "100%", aspectRatio: 1 }}
      className={className}
    />
  );
};

export default About;
