import React, { createContext, useContext, useState } from 'react';

const QuoteStateContext = createContext();

export const QuoteStateProvider = ({ children }) => {
  const [quote, setQuote] = useState(false);

  return (
    <QuoteStateContext.Provider value={{ quote, setQuote }}>
      {children}
    </QuoteStateContext.Provider>
  );
};

export const useQuoteState = () => useContext(QuoteStateContext);
