export const works = [
  {
    name: "Jumpin App",
    description: "A Virtual Queue Management App",
    img: "/assets/jumpin-logo.png",
    link: "",
  },
  {
    name: "DefiVerse",
    description: "DeFi platform that knows no limits",
    img: "https://defiverse-dev.vercel.app/assets/images/logo.svg",
    link: "https://defiverse-dev.vercel.app/",
  },
  {
    name: "Metakeep",
    description: "Zero Friction Enterprise Web3 Infrastructure",
    img: "https://metakeep.xyz/images/MetaKeep-1.png",
    link: "https://metakeep.xyz/",
  },
  {
    name: "ArbiBall",
    description: "Blockchain Lottery Experience",
    img: "https://arbiball-nine.vercel.app/Arbiball.svg",
    link: "https://arbiball-nine.vercel.app/",
  },
  {
    name: "Misfits Pluto",
    description: "Staking Dashboard",
    img: "https://stake.pluto.website/_next/static/media/PlutoText.c80b9029.svg",
    link: "https://stake.pluto.website/dashboard",
  },
  {
    name: "Creep Crew",
    description:
      "Burrowing Down the Rabbit Hole, Embarking on an Interdimensional Nightmare of Primordial Origins.",
    img: "/assets/creep-crew.jpeg",
    link: "https://www.creepcrew.io/",
  },
  {
    name: "IBS",
    description: "First in mind digital technology service provider",
    img: "/assets/ibs.svg",
    link: "https://www.ibscorporate.com/",
  },
  {
    name: "PredictRAM",
    description: "Transforming Risk Management Indusrty",
    img: "/assets/predictRam.png",
    link: "https://predictram.com/",
  },
];

export const worksV2 = [
  {
    name: "arb",
    source: "./v2/symbols/arb.png",
  },
  {
    name: "bionic",
    source: "./v2/symbols/bionic.png",
  },
  // {
  //   name: "cdac",
  //   source: "./v2/symbols/cdac.png",
  // },
  {
    name: "cog",
    source: "./v2/symbols/cog.png",
  },
  {
    name: "createProtocol",
    source: "./v2/symbols/createProtocol.png",
  },
  {
    name: "creepCrew",
    source: "./v2/symbols/creepCrew.png",
  },
  {
    name: "defiverse",
    source: "./v2/symbols/defiverse.png",
  },
  {
    name: "ibs",
    source: "./v2/symbols/ibs.png",
  },
  {
    name: "iip",
    source: "./v2/symbols/iip.png",
  },
  {
    name: "jump",
    source: "./v2/symbols/jump.png",
  },
  {
    name: "kgn",
    source: "./v2/symbols/kgn.png",
  },
  {
    name: "passengers",
    source: "./v2/symbols/passengers.png",
  },
  {
    name: "pluto",
    source: "./v2/symbols/pluto.png",
  },
  {
    name: "Kandola",
    source: "./v2/symbols/kandola.png",
  },
];

export const clientsV2 = [
  {
    name: "CreepCrew",
    source: "./v2/clients/creepCrew.svg",
  },
  {
    name: "",
    source: "./v2/clients/pluto.svg",
  },
  {
    name: "Juump",
    source: "./v2/clients/juump.svg",
  },
  {
    name: "Circle of Games",
    source: "./v2/clients/cog.svg",
  },
  {
    name: "Passengers",
    source: "./v2/clients/passenger.svg",
  },
  {
    name: "Bionic",
    source: "./v2/clients/bionic.svg",
  },
  {
    name: "Scoop.xyz",
    source: "./v2/clients/scoop.svg",
  },
  {
    name: "PredictRam",
    source: "./v2/clients/predictRam.svg",
  },
  {
    name: "KGeN",
    source: "./v2/clients/kgen.svg",
  },
  {
    name: "Create Protocol",
    source: "./v2/clients/createProtocol.svg",
  },
  {
    name: "IBS Corporation Ltd.",
    source: "./v2/clients/ibs.svg",
  },
  {
    name: "",
    source: "./v2/clients/arb.svg",
  },
  {
    name: "",
    source: "./v2/clients/defiverse.svg",
  },
  {
    name: "Kandola Network",
    source: "./v2/clients/kandola.svg",
  },
  {
    name: "",
    source: "./v2/clients/getsmart.svg",
  },
  {
    name: "Diamante",
    source: "./v2/clients/diamante.svg",
  },
  {
    name: "ALPHABOTS",
    source: "./v2/clients/alphabots.svg",
  },
];

export const feedback = [
  {
    name: "Abhisek Krishna",
    id: "@krishna",
    time: "8:51 PM",
    date: "22/07/2024",
    view: "156",
    repost: "4",
    comment: "12",
    pic: "./v2/clients/feedback/person.png",
    desc: (
      <p>
        <span className="text-[#1DA1F2]">Metaborong</span> took{" "}
        <span className="text-[#1DA1F2]"> Create Protocol</span> to the next
        level with their Web3 and Web2 skills. Impressive work!
      </p>
    ),
  },
  {
    name: "Girish Ahirwar",
    id: "@girish",
    time: "8:51 PM",
    date: "22/07/2024",
    view: "156",
    repost: "8",
    comment: "20",
    pic: "./v2/clients/feedback/person2.png",
    desc: (
      <p>
        <span className="text-[#1DA1F2]">Metaborong</span> really put their
        effort to write smart contracts for{" "}
        <span className="text-[#1DA1F2]"> Create Protocol</span> & their web 2.0
        team support was exceptional!
      </p>
    ),
  },
  {
    name: "Siddharth Banerjee",
    id: "@siddharth087",
    time: "8:51 PM",
    date: "22/07/2024",
    view: "156",
    repost: "13",
    comment: "21",
    pic: "./v2/clients/feedback/person3.png",
    desc: (
      <p>
        Imressive devOps & backend support by{" "}
        <span className="text-[#1DA1F2]">Metaborong</span>, heir expertise made
        a real difference. Highly recommend! 🚀
      </p>
    ),
  },
];
