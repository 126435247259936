export const gallery = [
  {
    name: "MONEY EXPO INDIA 2024",
    date: "Aug 17,2024",
    loc: "Mumbai, India",
    source: "https://ik.imagekit.io/metaborong/pic1.jpeg?updatedAt=1731521963370",
  },
  {
    name: "Google Cloud Community Days",
    date: "Aug 17,2024",
    loc: "Kolkata, India",
    source: "https://ik.imagekit.io/metaborong/pic2.jpeg?updatedAt=1731521964203",
  },
  {
    name: "UX India Kolkata Meetup 2024",
    date: "Aug 03,2024",
    loc: "Kolkata, India",
    source: "https://ik.imagekit.io/metaborong/pic3.jpeg?updatedAt=1731521964072",
  },
  {
    name: "India Blockchain Tour 3.0",
    date: "July 13,2024",
    loc: "Delhi, India",
    source: "https://ik.imagekit.io/metaborong/pic4.png?updatedAt=1731521963847",
  },
  {
    name: "Business Meet with Dr. h. c. Charles Singh",
    date: "May 22,2024",
    loc: "Bangkok, Thailand",
    source: "https://ik.imagekit.io/metaborong/pic5.png?updatedAt=1731521973693",
  },
  {
    name: "Business Meet with Innovative Software Consultancy",
    date: "May 20,2024",
    loc: "Bangkok, Thailand",
    source: "https://ik.imagekit.io/metaborong/pic6.jpeg?updatedAt=1731521964068",
  },
  {
    name: "ETH Tokyo 2024",
    date: "Aug 26,2024",
    loc: "Tokyo , Japan",
    source: "https://ik.imagekit.io/metaborong/pic7.png?updatedAt=1731525943558",
  },
  {
    name: "ETH Tokyo 2024",
    date: "Aug 26,2024",
    loc: "Tokyo , Japan",
    source: "https://ik.imagekit.io/metaborong/pic8.svg?updatedAt=1731521964331",
  },
  {
    name: "ETH Tokyo 2024",
    date: "Aug 26,2024",
    loc: "Tokyo , Japan",
    source: "https://ik.imagekit.io/metaborong/pic9.svg?updatedAt=1731521964781",
  },
  {
    name: "ETH Tokyo 2024",
    date: "Aug 26,2024",
    loc: "Tokyo , Japan",
    source: "https://ik.imagekit.io/metaborong/pic10.svg?updatedAt=1731521963841",
  },
  {
    name: "ETH Tokyo 2024",
    date: "Aug 26,2024",
    loc: "Tokyo , Japan",
    source: "https://ik.imagekit.io/metaborong/pic11.svg?updatedAt=1731521964127",
  },
  {
    name: "WEB X 2024",
    date: "Aug 27,2024",
    loc: "Tokyo , Japan",
    source: "https://ik.imagekit.io/metaborong/pic13.png?updatedAt=1731526158485",
  },
  {
    name: "WEB X 2024",
    date: "Aug 27,2024",
    loc: "Tokyo , Japan",
    source: "https://ik.imagekit.io/metaborong/pic14.png?updatedAt=1731526255085",
  },
  {
    name: "WEB X 2024",
    date: "Aug 28,2024",
    loc: "Tokyo , Japan",
    source: "https://ik.imagekit.io/metaborong/pic12.svg?updatedAt=1731521973767",
  },
  {
    name: "WEB X 2024",
    date: "Aug 28,2024",
    loc: "Tokyo , Japan",
    source: "https://ik.imagekit.io/metaborong/pic15.svg?updatedAt=1731521973203",
  },
  {
    name: "WEB X 2024",
    date: "Aug 28,2024",
    loc: "Tokyo , Japan",
    source: "https://ik.imagekit.io/metaborong/pic16.svg?updatedAt=1731521967692",
  },
  {
    name: "WEB X 2024",
    date: "Aug 28,2024",
    loc: "Tokyo , Japan",
    source: "https://ik.imagekit.io/metaborong/pic17.svg?updatedAt=1731521967737",
  },
  {
    name: "WEB X 2024",
    date: "Aug 28,2024",
    loc: "Tokyo , Japan",
    source: "https://ik.imagekit.io/metaborong/pic18.svg?updatedAt=1731521969566",
  },
  {
    name: "WEB X 2024",
    date: "Aug 29,2024",
    loc: "Tokyo , Japan",
    source: "https://ik.imagekit.io/metaborong/pic19.png?updatedAt=1731526352695",
  },
];
