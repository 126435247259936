import React, { useEffect } from "react";
// import BackToTopButton from "../components/BackToTopButton";
// import Header from "../components/Header/Header";
// import Hero from "../components/Hero";
import About from "../components/About";
import Services from "../components/Services";
// import Works from "../components/Works";
// import Clients from '../components/Clients';
import Testimonials from "../components/Testimonials";
import Banner from "../components/Banner";
import Banner2 from "../components/Banner2";
import Team from "../components/Team";
import Numbers from "../components/Numbers";
// import Footer from "../components/Footer";
import Footer from "../components/v2/Footer";
import Preloader from "../components/Preloader/Preloader";
import Hero from "../components/v2/Home/Home";
import NewHome from "../components/v2/Home/NewHome";
import Client from "../components/v2/Client/Client";
import ServicesSection from "../components/v2/Services";
import Works from "../components/v2/Works/Works";
import Gallery from "../components/v2/Gallery/Gallery";

const Home = () => {
  return (
    <div className="w-full">
      <Preloader />
      <div className="w-full bg-darkBlue flex items-center justify-center ">
        <div className="min-w-screen max-w-[1550px] lg:h-full h-fit text-offwhite overflow-x-hidden scroll-smooth w-full  flex flex-col justify-center items-center">
          {/* <Hero /> */}
          <NewHome />
          <About />
          <ServicesSection />
          <Works />
          <Client />
          {/* Testimonials */}
          {/* <Testimonials /> */}
          <Banner />
          <Numbers />
          {/* Team */}
          {/* <Team /> */}
          {/* Banner2 */}
          {/* // Footer */}
          <Gallery />
          <Banner2 />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Home;

// {/* v1 */}

// {/* hero */}
// <Header />
// <Hero />
// {/* About */}
// <About />
// {/* Services */}
// <Services />
// {/* Works */}
// <Works />
// {/* Client */}
// {/* <Clients /> */}
// {/* Banner */}
// <Footer />
// <BackToTopButton />
