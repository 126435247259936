import "./App.css";
import Home from "./pages/Home";
import Career from "./pages/Carrer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Fragment, useEffect } from "react";

import "./fonts/NeueMontreal-Bold.otf";
import "./fonts/NeueMontreal-Medium.otf";
import "./fonts/NeueMontreal-Regular.otf";
import "./fonts/NeueMontreal-Light.otf";
import "./fonts/Sk-Modernist-Bold.otf";
import "./fonts/Sk-Modernist-Regular.otf";
import "./fonts/Sk-Modernist-Mono.otf";
import ReactGA from "react-ga";

ReactGA.initialize("G-TEJ8M84H84");

export default function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <Fragment>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/career" element={<Career />} />
        </Routes>
      </Router>
    </Fragment>
  );
}
