import React, { useState } from 'react';

const CustomDropdown = ({ options, tag }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`bg-card-bg-2 py-4 px-4 text-left text-lg flex justify-between items-center rounded-xl ${options[0].label === "Product designer" ? "md:w-[40vw] w-full" : "md:w-[20vw] w-full"}`}
      >
        <p className={selectedOption ? `text-gray-100` : `text-gray-400`}>{selectedOption ? selectedOption.label : tag}</p>
        <svg
          className="h-5 w-5 inline-block ml-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      {isOpen && (
        <div className="absolute z-10 mt-1 py-4 w-full rounded-md bg-secondary-bg shadow-lg">
          {options.map((option) => (
            <button
              key={option.value}
              onClick={() => handleOptionSelect(option)}
              className="rounded-md w-full text-left text-lg px-4 py-3 hover:bg-[rgb(50,90,250)] flex justify-between
               focus:outline-none"
            >
              <p>{option.label}</p>
              <p className="bg-[rgb(62,100,253)] w-10 text-center rounded-xl">{option.vacency}</p>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
