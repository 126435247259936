import React from "react";
import CustomDropdown from "../components/CustomDropdown";
import Header from "../components/Header/Header";

const role = [
  { value: "1", label: "Product designer", vacency: "0" },
  { value: "2", label: "Frontend developer", vacency: "0" },
  { value: "3", label: "Backend developer", vacency: "0" },
];

const duration = [
  { value: "1", label: "Fulltime" },
  { value: "2", label: "Internship" },
  { value: "3", label: "Contract" },
];

const Carrer = () => {
  return (
    <div className="bg-primary-bg text-white overflow-hidden">
      {/* <div className="pt-28 lg:mt-0 bg-secondary-bg w-full h-full">
                <Header />
                <a href="/">
                    <img src="/assets/headerlogo.svg" alt="" className="top-5 left-10 absolute w-[100px] h-[100px]" />
                </a>
            </div> */}
      <div className="bg-secondary-bg w-full flex md:flex-row flex-col justify-center items-center lg:px-16 md:px-8 px-5 h-[500px] lg:h-[75vh] xl:h-[60vh] overflow-x-hidden">
        <div className="md:w-1/2 w-full">
          <h1 className="xl:text-6xl md:text-5xl text-3xl font-semibold leading-tight">
            Shape your future with Metaborong
          </h1>
          <p className="xl:text-lg md:text-base text-sm font-light mt-4 lg:pr-36 md:pr-16">
            Explore remote-friendly, dynamic oppurtunities and get associated
            with our vision to make work life simpler and growth oriented
          </p>
        </div>
        <div className="md:w-1/2 w-full h-full relative">
          <img
            src="/career/bg-frame.svg"
            alt=""
            className="absolute top-0 left-0 z-0 w-full h-full "
          />
          <img
            src="/career/man-suit.svg"
            alt=""
            className="xl:w-[350px] lg:w-[320px] md:w-[260px] w-[60vw] z-10 bottom-0 absolute xl:left-24 left-0"
          />
          <img
            src="/career/share.svg"
            alt=""
            className="bg-card-bg-1 border-8 xl:w-[90px] xl:h-[90px] lg:w-[80px] md:w-[80px] lg:h-[80px] md:h-[80px] w-[60px] h-[60px] border-[#365cf9] rounded-full lg:p-5 md:p-4 p-2 absolute top-6 md:left-1/3 left-[40%]"
          />
          <img
            src="/career/message.svg"
            alt=""
            className="bg-card-bg-1 border-8 xl:w-[90px] xl:h-[90px] lg:w-[80px] md:w-[80px] lg:h-[80px] md:h-[80px] w-[60px] h-[60px] border-[#365cf9] rounded-full lg:p-5 md:p-4 p-2 md:left-[55%] left-[60%] top-20 absolute"
          />
          <img
            src="/career/hierarchy.svg"
            alt=""
            className="bg-card-bg-1 border-8 xl:w-[90px] xl:h-[90px] lg:w-[80px] md:w-[80px] lg:h-[80px] md:h-[80px] w-[60px] h-[60px] border-[#365cf9] rounded-full lg:p-5 md:p-4 p-2 md:left-[70%] left-[75%] md:top-48 top-40 absolute"
          />
          <img
            src="/career/code.svg"
            alt=""
            className="bg-card-bg-1 border-8 xl:w-[90px] xl:h-[90px] lg:w-[80px] md:w-[80px] lg:h-[80px] md:h-[80px] w-[60px] h-[60px] border-[#365cf9] rounded-full lg:p-5 md:p-4 p-2 absolute md:top-80 top-60 md:left-[80%] left-[85%]"
          />
        </div>
      </div>
      <div className="h-full w-full md:px-16 px-6 py-10 bg-darkBlue">
        <h1 className="md:text-4xl font-normal text-2xl">
          Search for openings
        </h1>
        <div className="my-6 flex md:flex-row flex-col gap-6">
          <CustomDropdown options={role} tag="Find out vacancies" />
          <CustomDropdown options={duration} tag="Role type" />
        </div>
        <div className="w-full flex flex-col justify-center items-center my-16">
          <img src="/career/empty_dashboard.svg" alt="" />
          <h3 className="md:text-3xl font-normal text-xl text-slate-600">
            No openings currently!
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Carrer;
